(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

require("./scripts/libraries/portfolio");

require("./scripts/shortcodes");

},{"./scripts/libraries/portfolio":2,"./scripts/shortcodes":3}],2:[function(require,module,exports){
"use strict";

(function ($, window, document, undefined) {
  'use strict';

  var gridContainer = $('#grid-portfolio'),
      filtersContainer = $('#filters-portfolio'),
      wrap,
      filtersCallback;
  /*********************************
   init cubeportfolio
   *********************************/

  gridContainer.cubeportfolio({
    defaultFilter: '*',
    animationType: 'flipOutDelay',
    gapHorizontal: 35,
    gapVertical: 30,
    gridAdjustment: 'responsive',
    caption: 'overlayBottomReveal',
    displayType: 'lazyLoading',
    displayTypeSpeed: 100,
    // lightbox
    lightboxDelegate: '.cbp-lightbox',
    lightboxGallery: true,
    lightboxTitleSrc: 'data-title',
    lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} of {{total}}</div>',
    // singlePage popup
    singlePageDelegate: '.cbp-singlePage',
    singlePageDeeplinking: true,
    singlePageStickyNavigation: true,
    singlePageCounter: '<div class="cbp-popup-singlePage-counter">{{current}} of {{total}}</div>',
    singlePageCallback: function singlePageCallback(url, element) {
      // dont use ajax
      window.location = url; // to update singlePage content use the following method: this.updateSinglePage(yourContent)

      var t = this; // $.ajax({
      //     url: url,
      //     type: 'GET',
      //     dataType: 'html',
      //     timeout: 5000
      // })
      //     .done(function(result) {
      //         t.updateSinglePage(result);
      //     })
      //     .fail(function() {
      //         t.updateSinglePage("Error! Please refresh the page!");
      //     });
    },
    // single page inline
    singlePageInlineDelegate: '.cbp-singlePageInline',
    singlePageInlinePosition: 'above',
    singlePageInlineInFocus: true,
    singlePageInlineCallback: function singlePageInlineCallback(url, element) {// to update singlePage Inline content use the following method: this.updateSinglePageInline(yourContent)
    }
  });
  /*********************************
   add listener for filters
   *********************************/

  if (filtersContainer.hasClass('cbp-l-filters-dropdown')) {
    wrap = filtersContainer.find('.cbp-l-filters-dropdownWrap');
    wrap.on({
      'mouseover.cbp': function mouseoverCbp() {
        wrap.addClass('cbp-l-filters-dropdownWrap-open');
      },
      'mouseleave.cbp': function mouseleaveCbp() {
        wrap.removeClass('cbp-l-filters-dropdownWrap-open');
      }
    });

    filtersCallback = function filtersCallback(me) {
      wrap.find('.cbp-filter-item').removeClass('cbp-filter-item-active');
      wrap.find('.cbp-l-filters-dropdownHeader').text(me.text());
      me.addClass('cbp-filter-item-active');
      wrap.trigger('mouseleave.cbp');
    };
  } else {
    filtersCallback = function filtersCallback(me) {
      me.addClass('cbp-filter-item-active').siblings().removeClass('cbp-filter-item-active');
    };
  }

  filtersContainer.on('click.cbp', '.cbp-filter-item', function () {
    var me = $(this);

    if (me.hasClass('cbp-filter-item-active')) {
      return;
    } // get cubeportfolio data and check if is still animating (reposition) the items.


    if (!$.data(gridContainer[0], 'cubeportfolio').isAnimating) {
      filtersCallback.call(null, me);
    } // filter the items


    gridContainer.cubeportfolio('filter', me.data('filter'), function () {});
  });
  /*********************************
   activate counter for filters
   *********************************/

  gridContainer.cubeportfolio('showCounter', filtersContainer.find('.cbp-filter-item'), function () {
    // read from url and change filter active
    var match = /#cbpf=(.*?)([#|?&]|$)/gi.exec(location.href),
        item;

    if (match !== null) {
      item = filtersContainer.find('.cbp-filter-item').filter('[data-filter="' + match[1] + '"]');

      if (item.length) {
        filtersCallback.call(null, item);
      }
    }
  });
  /*********************************
   add listener for load more
   *********************************/

  $('.cbp-l-loadMore-button-link').on('click.cbp', function (e) {
    e.preventDefault();
    var clicks,
        me = $(this),
        oMsg;

    if (me.hasClass('cbp-l-loadMore-button-stop')) {
      return;
    } // get the number of times the loadMore link has been clicked


    clicks = $.data(this, 'numberOfClicks');
    clicks = clicks ? ++clicks : 1;
    $.data(this, 'numberOfClicks', clicks); // set loading status

    oMsg = me.text();
    me.text('LOADING...'); // perform ajax request

    $.ajax({
      url: me.attr('href'),
      type: 'GET',
      dataType: 'HTML'
    }).done(function (result) {
      var items, itemsNext; // find current container

      items = $(result).filter(function () {
        return $(this).is('div' + '.cbp-loadMore-block' + clicks);
      });
      gridContainer.cubeportfolio('appendItems', items.html(), function () {
        // put the original message back
        me.text(oMsg); // check if we have more works

        itemsNext = $(result).filter(function () {
          return $(this).is('div' + '.cbp-loadMore-block' + (clicks + 1));
        });

        if (itemsNext.length === 0) {
          me.text('NO MORE WORKS');
          me.addClass('cbp-l-loadMore-button-stop');
        }
      });
    }).fail(function () {// error
    });
  });
})(jQuery, window, document);

},{}],3:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  "use strict"; //TESTIMONIAL

  if ($(".ts-list-testimonial").length > 0) {
    var slide_speed = parseInt($(".ts-list-testimonial").attr("data-speed")) * 1000;

    if (isNaN(slide_speed)) {
      slide_speed = 4000;
    }

    $(".ts-list-testimonial").owlCarousel({
      autoPlay: slide_speed,
      slideSpeed: 1000,
      navigation: false,
      pagination: true,
      singleItem: true
    });
  } //FUNFACT


  $('.ts-funfact').appear(function () {
    var count_element = $('.funfact-number', this).html();
    $(".funfact-number", this).countTo({
      from: 0,
      to: count_element,
      speed: 2500,
      refreshInterval: 50
    });
  }); //TAB

  $('.ts-horizontalTab').easyResponsiveTabs({
    type: 'default',
    width: 'auto',
    fit: true,
    animation: 'slide'
  });

  if ($(window).width() > 767) {
    $('.ts-horizontalTab').each(function () {
      var _this = $(this);

      var numberTab = _this.find(".resp-tabs-list li").size();

      var wTab = 100 / numberTab;

      _this.find(".resp-tabs-list li").css("width", wTab + "%");
    });
  }

  $('.ts-verticalTab').easyResponsiveTabs({
    type: 'vertical',
    fit: true
  });

  if ($(window).width() >= 768) {
    $('.ts-verticalTab').each(function () {
      var _thisV = $(this);

      var heightTab = _thisV.find('.resp-tabs-list li[aria-controls="tab_item-0"]').outerHeight();

      var numberTabV = _thisV.find(".resp-tabs-list li").size();

      var hTab = heightTab * numberTabV - 1;

      _thisV.find(".resp-tabs-container").css("min-height", hTab + "px");
    });
  } //TWITTER SLIDER


  if ($("#owl-twitter").length > 0) {
    $(".twitter-slide").owlCarousel({
      autoPlay: 4000,
      slideSpeed: 1000,
      navigation: false,
      pagination: true,
      singleItem: true
    });
  } //CLIENT SLIDER


  if ($(".ts-client-list ul").length > 0) {
    $(".ts-client-list ul").owlCarousel({
      items: 3,
      autoPlay: 4000,
      slideSpeed: 1000,
      navigation: false,
      pagination: false,
      singleItem: false,
      navigationText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      itemsCustom: [[0, 1], [320, 2], [480, 2], [768, 4], [992, 4], [1200, 5]]
    });
  } // Skill bar


  $('.skillbar').each(function () {
    $(this).find('.skillbar-bar').animate({
      width: $(this).attr('data-percent')
    }, 6000);
  }); // Portfolio slider

  if ($(".ts-portfolio-slider").length > 0) {
    $(".ts-portfolio-slider").owlCarousel({
      autoPlay: 4000,
      slideSpeed: 1000,
      navigation: true,
      pagination: false,
      singleItem: true,
      navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
    });
  } //Gallery blog


  if ($(".blog-grallery").length > 0) {
    $(".blog-grallery").owlCarousel({
      autoPlay: 4000,
      slideSpeed: 3000,
      navigation: true,
      pagination: false,
      singleItem: true,
      navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
    });
  }
});

},{}]},{},[1]);
